.rc-pagination-item {
  display: inline-block;
  min-width: 28px;
  height: 28px;
  margin-right: 3px;
  font-family: Arial;
  line-height: 26px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 2px;
  outline: 0;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  @apply bg-black;
  @apply border-black;
}

.rc-pagination-item a {
  display: block;
  padding: 0 6px;
  @apply text-white;
  transition: none;
}

.rc-pagination-prev .rc-pagination-item-link,
.rc-pagination-next .rc-pagination-item-link {
  display: block;
  width: 100%;
  height: 100%;
  font-size: 12px;
  text-align: center;
  @apply bg-black;
  @apply border-black;
  @apply text-white;
  @apply text-lg;
  border-radius: 2px;
  outline: none;
  transition: all 0.3s;
}
.rc-pagination-item-active {
  font-weight: 500;
  background: black !important;
  @apply border-primary;
  @apply text-primary !important;
}
