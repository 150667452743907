@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Poppins';
}

/* Scrollbar styles */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 100;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 100;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 100;
}